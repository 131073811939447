import { mapMutations, mapState } from 'vuex'
import dirtyType from '@/store/type/dirtyType'

export default {
    computed: {
        ...mapState({
            dirty: (state) => state.dirty.dirty,
        }),
    },

    methods: {
        ...mapMutations({
            setDialog: dirtyType.mutations.SET_DIALOG,
            setNextPage: dirtyType.mutations.SET_NEXT_PAGE,
        }),
    },

    beforeRouteLeave(to, from, next) {
        if (this.dirty) {
            this.setNextPage(to)
            this.setDialog(true)
        } else {
            next()
        }
    },
}
