<template>
    <div>
        <d-table
            :items="items"
            :headers="headers"
            :filters.sync="filters"
            row-action
            class="inlined table"
            @onRowSelected="onRowSelected"
        >
            <!-- filename column -->
            <template v-slot:[`item.file_name`]="{ item }">
                <div>{{ item.file_name }}.{{ item.extension }}</div>
            </template>

            <!-- row actions -->
            <template v-slot:rowActions="{ item }">
                <table-button
                    :tooltip="$t('invoices.download')"
                    color="blue-grey"
                    icon="file_download"
                    approve-color="error"
                    @execute="
                        downloadFile(item.id, item.file_name, item.extension)
                    "
                ></table-button>
                <table-button
                    v-if="isSuperAdmin"
                    :tooltip="$t('actions.delete')"
                    color="blue-grey"
                    icon="delete"
                    approve-color="error"
                    @execute=";(selectedFile = item), (dialog = true)"
                ></table-button>
            </template>
        </d-table>
        <confirm-modal
            v-model="dialog"
            :title="$t('texts.are_you_sure')"
            :text="
                $t('texts.you_will_delete_this_item', {
                    item: `${selectedFileName}.${selectedFileExtension}`,
                })
            "
            approve-color="red"
            :approve-text="$t('actions.delete')"
            @onApprove="deleteFile(selectedFile.id)"
            @onCancel="selectedFile = null"
        ></confirm-modal>
    </div>
</template>

<script>
import SelectApiClientType from '@/api/SelectApiClientType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import RestApiCollection from '@/api/RestApiCollection'
import MapperType from '@/services/mapper/MapperType'
import TableButton from '@/components/table/buttons/TableButton'
import InlineAdminsHeaders from '@/pages/invoices/mixins/InlineFilesHeaders'
import ConfirmModal from '@/components/confirm/ConfirmModal'

export default {
    components: {
        TableButton,
        ConfirmModal,
    },

    mixins: [DataIteratorMixin, DataTableMixin, InlineAdminsHeaders, AuthMixin],
    props: {
        billingFolderId: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        relationId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: RestApiType.COMPANY_INVOICE,
            selectApiClientType: SelectApiClientType,
            mapperType: MapperType,
            items: [],
            dialog: false,
            selectedFile: null,
        }
    },
    computed: {
        selectedFileName: {
            get() {
                return this.selectedFile?.file_name || null
            },
            set(val) {
                this.selectedFile.file_name = val
            },
        },
        selectedFileExtension: {
            get() {
                return this.selectedFile?.extension || null
            },
            set(val) {
                this.selectedFile.extension = val
            },
        },
    },
    mounted() {
        this.loadItems()
    },
    methods: {
        deleteFile(fileId) {
            this.busy = true
            RestApiCollection.get(this.api)
                .deleteFile(
                    this.type,
                    this.relationId,
                    this.billingFolderId,
                    fileId
                )
                .then(() => {
                    this.loadItems()
                })
                .catch(() => {})
                .finally(() => {
                    this.busy = false
                    this.selectedFile = null
                })
        },
        downloadFile(fileId, fileName, extension) {
            this.busy = true
            RestApiCollection.get(this.api)
                .downloadFile(
                    this.type,
                    this.relationId,
                    this.billingFolderId,
                    fileId
                )
                .then((response) => {
                    const blob = new Blob([response], {})
                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = `${fileName}.${extension}`
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(() => {})
                .finally(() => {
                    this.busy = false
                })
        },
        loadItems() {
            RestApiCollection.get(this.api)
                .getFolderFiles(
                    this.type,
                    this.relationId,
                    this.billingFolderId
                )
                .then((data) => {
                    this.items = data
                })
                .catch((e) => {
                    console.log(e)
                })
        },
    },
}
</script>
