<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapperType.INVOICE_FORM"
        :entity-id="entityId"
        :title="title"
        :disabled="disabled"
        disable-delete-button
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <!-- INVOICE -->

        <v-col cols="12">
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('invoices.invoice_data') }}</h3>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-lg-4">
                            <text-input
                                v-model="form.invoiceNumber"
                                v-validate="'required|min:1|max:100|space'"
                                name="invoiceNumber"
                                label="invoices.invoice_number"
                                :error-messages="
                                    errors.collect('invoiceNumber')
                                "
                                :data-vv-as="$t('invoices.invoice_number')"
                            ></text-input>
                        </div>
                    </div>
                    <form-two-col-row>
                        <template v-slot:col1>
                            <date-picker-input
                                v-model="form.periodStart"
                                v-validate.immediate="''"
                                :label="$t('invoices.period_start')"
                                name="periodStart"
                                :error-messages="errors.collect('periodStart')"
                                :data-vv-as="$t('invoices.period_start')"
                                only-date
                            ></date-picker-input>
                        </template>
                        <template v-slot:col2>
                            <date-picker-input
                                v-model="form.periodEnd"
                                :label="$t('invoices.period_end')"
                                name="periodEnd"
                                :error-messages="errors.collect('periodEnd')"
                                :data-vv-as="$t('invoices.period_end')"
                                :min="form.periodStart"
                                only-date
                            ></date-picker-input>
                        </template>
                    </form-two-col-row>

                    <form-two-col-row>
                        <template v-slot:col1>
                            <div class="mt-4">
                                <!-- coupon minimumPrice -->
                                <entity-select-input
                                    v-model="companyId"
                                    v-validate.immediate="''"
                                    label="invoices.companies"
                                    name="company"
                                    :entity="selectApiClientType.COMPANY"
                                    :data-vv-as="$t('invoices.companies')"
                                    :error-messages="errors.collect('company')"
                                    autocomplete
                                    :disabled="
                                        form.restaurant || entityId
                                            ? true
                                            : false
                                    "
                                ></entity-select-input>
                            </div>
                        </template>
                        <template v-slot:col2>
                            <div class="mt-4">
                                <!-- coupon minimumPrice -->
                                <entity-select-input
                                    v-model="restaurantId"
                                    v-validate.immediate="''"
                                    label="invoices.restaurants"
                                    name="restaurant"
                                    :entity="selectApiClientType.RESTAURANT"
                                    :data-vv-as="$t('invoices.restaurants')"
                                    :error-messages="
                                        errors.collect('restaurant')
                                    "
                                    autocomplete
                                    :disabled="
                                        form.company || entityId ? true : false
                                    "
                                ></entity-select-input>
                            </div>
                        </template>
                    </form-two-col-row>

                    <form-two-col-row>
                        <template v-slot:col1>
                            <div>
                                <text-input
                                    v-model="form.amount"
                                    v-validate="'required|min:1|max:100|space'"
                                    name="amount"
                                    label="invoices.amount"
                                    :error-messages="errors.collect('amount')"
                                    :data-vv-as="$t('invoices.amount')"
                                    type="number"
                                ></text-input>
                            </div>
                        </template>
                        <template v-slot:col2>
                            <div>
                                <text-input
                                    v-model="form.amountDue"
                                    v-validate="'required|min:1|max:100|space'"
                                    name="amountDue"
                                    label="invoices.amount_due"
                                    :error-messages="
                                        errors.collect('amountDue')
                                    "
                                    :data-vv-as="$t('invoices.amount_due')"
                                    type="number"
                                ></text-input>
                            </div>
                        </template>
                    </form-two-col-row>
                </v-card-text>
            </v-card>
            <v-card color="grey lighten-5" flat>
                <v-card-title primary-title>
                    <h3 class="headline">{{ $t('invoices.upload_file') }}</h3>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <v-file-input
                            ref="uploader"
                            v-model="form.files"
                            accept=""
                            :multiple="true"
                            :clearable="false"
                            :label="$t('invoices.upload_file')"
                            :append-icon="form.files ? 'close' : undefined"
                            :small-chips="true"
                        />
                    </div>
                    <inline-files-table
                        v-if="form.billingFolderId"
                        ref="inlinefiles"
                        :billing-folder-id="form.billingFolderId"
                        :type="type"
                        :relation-id="myEntityId"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </base-form>
</template>

<script>
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import routeType from '@/router/routeType'
import FormBase from '@/components/mixins/FormBase'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import DatePickerInput from '@/components/form/Inputs/DatePickerInput'
import ApiClientType from '@/api/RestApiType'
import IdFromIri from '@/services/IdFromIri'
import InlineFilesTable from './InlineFilesTable.vue'

export default {
    components: {
        InlineFilesTable,
        TextInput,
        EntitySelectInput,
        DatePickerInput,
        FormTwoColRow,
    },
    mixins: [FormBase],
    props: {
        itemid: {
            type: String,
            default: null,
        },
        itemtype: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            form: {
                files: null,
                type: '',
                relationId: null,
            },
            api: ApiClientType.INVOICES,
            editRoute: routeType.INVOICES_EDIT,
            listRoute: routeType.INVOICES_DASHBOARD,
            entity: 'labels.invoice',
            selectedFile: null,
            isSelecting: false,
            busy: false,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        title() {
            return this.entityId
                ? this.$t('invoices.edit_page_title', {
                      entityName: this.form.invoiceNumber,
                  })
                : this.$t('invoices.create_page_title')
        },
        type() {
            if (this.form.restaurant) {
                return 'restaurant'
            } else if (this.form.company) {
                return 'company'
            }
            return ''
        },
        myEntityId: function () {
            return this.form && this.companyId
                ? IdFromIri.getId(this.companyId)
                : IdFromIri.getId(this.restaurantId)
        },
        restaurantId: {
            get() {
                return this.form?.restaurant
                    ? this.form.restaurant['@id']
                    : null
            },

            set(v) {
                this.$set(this.form, 'restaurant', { '@id': v })
            },
        },
        companyId: {
            get() {
                return this.form?.company ? this.form.company['@id'] : null
            },

            set(v) {
                this.$set(this.form, 'company', { '@id': v })
            },
        },
    },
    watch: {
        type: {
            handler(newVal) {
                this.$set(this.form, 'type', newVal)
            },
        },
        myEntityId: {
            handler(newVal) {
                this.$set(this.form, 'relationId', newVal)
            },
        },
    },
    created() {
        if (this.itemid == null) return

        if (this.itemtype === 'company') {
            this.$set(this.form, 'company', {
                '@id': `/api/v1/companies/${this.itemid}`,
            })
        } else if (this.itemtype === 'restaurant') {
            this.$set(this.form, 'restaurant', {
                '@id': `/api/v1/restaurants/${this.itemid}`,
            })
        }
        this.$set(this.form, 'type', this.itemtype)
    },
    methods: {
        updated() {
            setTimeout(() => {
                this.$refs.inlinefiles.loadItems()
            }, 500)
        },
        onButtonClick() {
            this.isSelecting = true
            window.addEventListener(
                'focus',
                () => {
                    this.isSelecting = false
                },
                { once: true }
            )

            this.$refs.uploader.click()
        },
        async onFileChanged(e) {
            this.form.files = new FormData()
            if (e.target.files.length > 0) {
                for (const file of e.target.files) {
                    this.form.files.append('file', file)
                    this.form.files.append(
                        'title',
                        file.name.substring(0, file.name.lastIndexOf('.'))
                    )
                }
            }
        },
        editRedirectParams: (data) => ({ entityId: data.id }),
    },
}
</script>
