export default {
    computed: {
        headers() {
            return [
                {
                    value: 'file_name',
                    text: this.$t('labels.name'),
                    sortable: true,
                    type: 'string',
                },
            ]
        },
    },
}
